import React  from "react";
import { useState, useEffect } from "react";
import DatePicker from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import './style.css';
import {ViewerDataModel} from './Utils/ViewerDataModel'
import repository from "./Utils/RepositoryManager";
import authManager from "./Utils/AuthenticationManager";
import { auth } from './Utils/FirebaseIntegration';
import { onAuthStateChanged } from "firebase/auth";

function App() {
  const defaultCardRarityValue = 1
  const defaultCardLevelValue = 1
  const radioRegisterValue = 'register'
  const radioUpdateValue = 'update'

  const twitchNamePlaceholder = 'Nome da Twitch'
  const cardNamePlaceholder = 'Nome no card'
  const bornDatePlaceholder = "Data de nascimento"
  const fruitPlaceholder = 'Fruta'
  const statePlaceholder = 'Estado'

  const dateFormat = 'dd/MM/yyyy'

  const [twitchName, setTwitchName] = useState('')
  const [cardName, setCardName] = useState('')
  const [cardRarity, setCardRarity] = useState(defaultCardRarityValue)
  const [cardLevel, setCardLevel] = useState(defaultCardLevelValue)
  const [bornDate, setBornDate] = useState('')
  const [fruit, setFruit] = useState('')
  const [state, setState] = useState('')
  const [radio, setRadio] = useState(radioRegisterValue)
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  var createdAt = ''

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) { 
          setIsLoggedIn(true);  
        } else {
          setIsLoggedIn(false);
        }
      });

    return () => {
      unsubscribe();
    };
    }, []);

  function sendData() {
    const emptyFields = [];

    if (!twitchName) {
      emptyFields.push("NomeTwitch")
    }

    if (!cardName) {
      emptyFields.push("NomeCard")
    }
    
    if (!cardRarity) {
      emptyFields.push("RaridadeCard")
    }

    if (!cardLevel) {
      emptyFields.push("LevelCard")
    }

    if (!bornDate) {
      emptyFields.push("DataNascimento")
    }

    if (!fruit) {
      emptyFields.push("Fruta")
    }

    if (!state) {
      emptyFields.push("Estado")
    }

    if (emptyFields.length === 0) {
     
      let data = new ViewerDataModel(twitchName, cardName, cardRarity, cardLevel, bornDate, fruit, state, createdAt)
      repository.registerViewerData(data, radio === radioUpdateValue)
      createdAt = ''
    } else {
      alert("Os seguintes campos (" + emptyFields + ") estão vazios.")
    }
  }

  function handleRadioButtonChange(event) {
    setRadio(event.target.value)

    if (event.target.value === radioUpdateValue) {
      var twitchName = prompt('Digite o nome de usuário da twitch')

      repository.getViewerData(twitchName).then ( result => {
        if (result != null) {
          setTwitchName(result.twitchName)
          setCardName(result.cardName)
          setCardRarity(parseInt(result.cardRarity))
          setCardLevel(parseInt(result.cardLevel))
          setBornDate(result.bornDate)
          setFruit(result.fruit)
          setState(result.state)
          createdAt = result.createdAt
        } else {
          alert("Usuário '" + twitchName + "' não encontrado")
          setRadio(radioRegisterValue);
          createdAt = ''
        }
      });
    }
  }

  function authenticate() {
    authManager.authenticate();
  }

  function logout() {
    authManager.logout();
  }

  function SessionButton() {
    if (isLoggedIn === null) {
      return <a ></a>
    } else if (isLoggedIn) {
      return <button className="button sessionButton" onClick={logout}>Logout</button>
    } else if (!isLoggedIn) {
      return <button className="button sessionButton" onClick={authenticate}>Authenticate</button>
    }
  }

  return (
    <div className="App">
      <div className="container">
        <h1 className="title">Cadastro/Atualização</h1>

        <div className="container_input">
          <SessionButton/>

          <div className="radio-area">
            <div className="radio">
              <input type="radio" name="type" value={radioRegisterValue} onChange={ (e) => setRadio(e.target.value) } checked={ radio === radioRegisterValue } className="radio-input"/>
              <label>Cadastrar</label>
            </div>

            <div className="radio">
              <input type="radio" name="type" value={radioUpdateValue} onChange={handleRadioButtonChange} checked={ radio === radioUpdateValue } className="radio-input"/>
              <label>Atualizar</label>
            </div>
          </div>

          <input type="text" placeholder={twitchNamePlaceholder} value={twitchName} onChange={ (e) => setTwitchName(e.target.value) }/>
          <input type="text" placeholder={cardNamePlaceholder} value={cardName} onChange={ (e) => setCardName(e.target.value) }/>
          <input type="number" min="1" max="4" value={cardRarity} onChange={ (e) => setCardRarity(e.target.value) }/>
          <input type="Number" min="1" max="3" value={cardLevel} onChange={ (e) => setCardLevel(e.target.value) }/>
          <DatePicker placeholderText={bornDatePlaceholder} locale={ptBR} dateFormat={dateFormat} selected={bornDate} type="text" onChange={ (date) => setBornDate(date) }/>
          <input type="text" placeholder={fruitPlaceholder} value={fruit} onChange={ (e) => setFruit(e.target.value) }/>
          <input type="text" placeholder={statePlaceholder} value={state} onChange={ (e) => setState(e.target.value) }/>

          <button className="button" onClick={sendData}>Enviar</button>
        </div>
      </div>
    </div>
  );
}

export default App;