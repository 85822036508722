import { auth } from './FirebaseIntegration';
import { signInWithPopup, signOut, GoogleAuthProvider } from "firebase/auth";

export default class AuthenticationManager {
    static authenticate = async() => {
        const provider = new GoogleAuthProvider();
    
        signInWithPopup(auth, provider)
          .then((result) => {
          }).catch((error) => {
            console.log(error);
            console.log(error.code);
            console.log(error.message);
            console.log(error.customData.email);
            console.log(GoogleAuthProvider.credentialFromError(error));
          });
      }
    
      static logout = async() => {
        signOut(auth)
        .catch((error) => {
          console.log(error);
        });
      }
}